<template>
    <div class="loading_box" @click.stop.prevent>
        <div class="next-loading-bg">
            <div class="next-loading-tip load_img"></div>
            <div class="loading_text">加载中...</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Loading',
    data () {
        return {
            description: 'loading',
        }
    },
}
</script>

<style lang="scss" scoped>
.loading_box {
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
.loading_text {
    font-size: 14px;
    color: #3a3a3a;
}
.next-loading-bg {
    width: 128px;
    height: 112px;
    background: #ffffff;
    // box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.6);
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.next-loading-tip {
    width: 48px;
    height: 48px;
    z-index: 4;
    background: url('../../assets/img/default/loading.png') 0 0 no-repeat;
}
.load_img {
    animation: go 1s steps(30) infinite;
}
@keyframes go {
    to {
        background-position: -1440px 0;
    }
}
</style>