let BASE_URL = {
    development: 'https://api.test.acme-ai.com/gafc/webapi/soccer',
    production: 'https://api.test.acme-ai.com/gafc/webapi/soccer',
};

let homeTeam = {
    id: '62578e5217e5004bde737373',
    name: '上海农商银行队',
    videoId: '62563317dc32b1000135ad28',
    color: '#6C00C0',
    logo: require('@/assets/img/match-report/shanghai.svg'),
    clothes: require('@/assets/img/match-report/shanghai-clothes.svg'),
};

let awayTeam = {
    id: '62578e71a7854c0fc07cbdc2',
    name: '北京女足',
    videoId: '6256a1336f17ed0001476d0d',
    color: '#F3C243',
    logo: require('@/assets/img/match-report/beijing.svg'),
    clothes: require('@/assets/img/match-report/beijing-clothes.svg'),
};

let TIMES = {
    firstHalfStartSecond: 312.39985,
    firstHalfEndSecond: 3073.394395,
    secondHalfStartSecond: 4045.776856,
    secondHalfEndSecond: 6911.749929,
};
TIMES['middleSecond'] = TIMES.secondHalfStartSecond - TIMES.firstHalfEndSecond; // 中场休息时长
TIMES['totalTime'] =
    TIMES.firstHalfEndSecond -
    TIMES.firstHalfStartSecond +
    (TIMES.secondHalfEndSecond - TIMES.secondHalfStartSecond);
TIMES['totalTime'] = +TIMES['totalTime'].toFixed(2);

// 上海vs北京（北京事件） （上海事件） (测试视频后面修改)
let VIDEOIDS = homeTeam.videoId + ',' + awayTeam.videoId;

export default {
    BASE_URL: 'https://api.test.acme-ai.com/gafc/webapi/soccer',
    // BASE_URL: 'http://10.0.0.41:81/webapi/soccer',
    homeTeam,
    awayTeam,
    TIMES,
    VIDEOIDS,
};
