<template>
    <div
        id="app"
        :style="{'--home-color': $config.homeTeam.color, '--away-color': $config.awayTeam.color}"
    >
        <loading v-if="$store.state.loading" />
        <!-- <keep-alive> -->
        <router-view />
        <!-- </keep-alive> -->
    </div>
</template>

<script>
import loading from '@/components/loading/loading.vue'
export default {
    name: 'App',
    components: {
        loading
    },
    created () {
        // 在页面加载时读取localStorage
        if (localStorage.getItem('store')) {
            this.$store.replaceState(
                Object.assign({}, this.$store.state, JSON.parse(localStorage.getItem('store')))
            )
        }

        // 语言设置
        let lang = localStorage.getItem('language')
        if (!lang) localStorage.setItem('language', 'en')
        this.$store.state.language = lang || 'zh'
        // 在页面刷新时将store保存到localStorage里
        window.addEventListener('beforeunload', () => {
            localStorage.setItem('store', JSON.stringify(this.$store.state))
        })
    },
}
</script>
<style lang="scss">
:root {
    --main: #3663e1; // 主题色
    --football-main: #6c00c0; // 足球主题色
    --bg-color: #f6f6f6; // 背景色
    --page-body: #131314; // 主体
    --header: #ffffff; // header
    --header-sceond: #191a1b; //二级头部颜色
    --input-bgc: #141415; // 输入框颜色
    --dialog-body: #ffffff; // 弹窗主体色
    --a-red: #e1233b; // 红辅色
    --a-yellow: #ffbf52; // 黄辅色
    --a-green: #4cd964; // 绿辅色
    --select: #343536; // 选中高亮
    --icon-hover: #73738e; // icon 图标划过
    --c1C1D1F: #1c1d1f;
    --font-color: #3a3a3a; // 主要文字
    --text-tips: #b0b0c8; // 提示文字
    --text-secondary: #73738e; // 次要文字
    --title-bg: #13141b; // 标题背景

    // 公共组件颜色
    --svgHoverBg: #eef1f6; // svg 滑过背景色
    --viMoreHoverBg: #eef1f6; // more组件 hover背景颜色
    --timeLineBg: #e9e9e9; // timeLine组件 背景色
    --timeLineBorder: rgb(246, 246, 246); // timeLine组件 边框线颜色

    // element UI组件颜色
    --sliderBarBg: rgba(176, 176, 200, 0.5); // silder 滑块条背景色
}
</style>
