import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        language: 'en', // 语言
        user: {}, // 登录用户
        loading: false, // loading
    },
    mutations: {
        // 改变当前语言
        changeLanguage(state, type) {
            state.language = type;
        },
        // 设置 loading
        setLoading(state, data) {
            state.loading = data;
        },
    },
    getters: {},
    actions: {},
});
