import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};
export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: '/match-report',
        },
        {
            // 比赛报告详情页
            path: '/match-report',
            name: 'match-report',
            redirect: '/match-report/football/summary', // 重定向
            component: () => import('@/views/match-report/index.vue'),
            children: [
                // 足球
                {
                    // 比赛报告 -- Summary 报告
                    path: '/match-report/football/summary',
                    name: 'football-summary',
                    component: () =>
                        import('@/views/match-report/football-item-report/summary.vue'),
                },
                {
                    // 比赛报告 -- Box Score 报告
                    path: '/match-report/football/box-score',
                    name: 'football-box-score',
                    component: () =>
                        import('@/views/match-report/football-item-report/box-score.vue'),
                },
                {
                    // 比赛报告 -- Game Charts  报告
                    path: '/match-report/football/game-charts',
                    name: 'football-game-charts',
                    component: () =>
                        import('@/views/match-report/football-item-report/game-charts.vue'),
                },
                {
                    // 比赛报告 -- Play-By-Play 报告
                    path: '/match-report/football/play-by-play',
                    name: 'football-play-by-play',
                    component: () =>
                        import('@/views/match-report/football-item-report/play-by-play.vue'),
                },
                {
                    // 比赛报告 -- 运动表现分析 报告
                    path: '/match-report/football/athletic-performance',
                    name: 'athletic-performance',
                    component: () =>
                        import(
                            '@/views/match-report/football-item-report/athletic-performance.vue'
                        ),
                },
                {
                    // 比赛报告 -- 专家分析 报告
                    path: '/match-report/football/expert-analysis',
                    name: 'expert-analysis',
                    component: () =>
                        import('@/views/match-report/football-item-report/expert-analysis.vue'),
                },
            ],
        },
        {
            // 比赛报告详情页
            path: '/player-info',
            name: 'player-info',
            component: () => import('@/views/match-report/football-item-report/player-info.vue'),
        },
        // footovision
        {
            path: '/footovision',
            name: 'footovision',
            component: () => import('@/views/footovision/index.vue'),
        },
    ],
});
