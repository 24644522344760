
const storage = {
    /* localStorage */
    ls: {
        set(key, value) {
            if (typeof value === 'object') {
                localStorage.setItem(key, JSON.stringify(value));
            } else {
                localStorage.setItem(key, value);
            }
        },
        get(key) {
            const content = localStorage.getItem(key)
            if (content) {
                  try {
                    return JSON.parse(content)
                } catch (e) {
                    return content
                }
            } else {
                return null
            }
        },
        remove(key) {
            localStorage.removeItem(key)
        },
        clear() {
            localStorage.clear()
        }
    },
    /* sessionStorage */
    ss: {
        set(key, value) {
            if (typeof value === 'object') {
                sessionStorage.setItem(key, JSON.stringify(value));
            } else {
                sessionStorage.setItem(key, value);
            }
        },
        get(key) {
            const content = sessionStorage.getItem(key)
            if (content) {
                try {
                    return JSON.parse(content)
                } catch (e) {
                    return content
                }
            } else {
                return null
            }
        },
        remove(key) {
            sessionStorage.removeItem(key)
        },
        clear() {
            sessionStorage.clear()
        }
    }
}
export default storage;
